import { useSessionStore } from '@/stores/session';
export default {
  setup: function setup() {
    var sessionStore = useSessionStore();
    return {
      sessionStore: sessionStore
    };
  },
  created: function created() {
    /* Start user session */
    this.sessionStore.setLoadingSessionId();
    this.sessionStore.startSession();
    this.topProgressBar = false;
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  }
};