import { getBaseUrl } from '@/utils/functions'

// General
// Replace `http://template.dev.local` by your server base url
export var APP_BASE_URL = process.env.NODE_ENV == 'development' ? 'http://edimamel.dev.local' : getBaseUrl()
export const X_API_KEY = 'b47e8c19-9e29-4287-acb1-4f34481402a0'
export const API_REQUEST_HEADERS = {
    'X-API-Key': X_API_KEY
}

// DB Api
export var DATABASE_API_BASE_URL = APP_BASE_URL + '/breeze-server/db-api/'

// Storage Api
export var STORAGE_API_BASE_URL = APP_BASE_URL + '/breeze-server/sto-api/'

// JSON Api
export var FILE_API_BASE_URL = APP_BASE_URL + '/breeze-server/file-api/'

// Client IP Api
export var CLIENT_IP_API_BASE_URL = APP_BASE_URL + '/breeze-server/client-ip-api/'

// IP Location Api
export var IP_LOCATION_API_BASE_URL = APP_BASE_URL + '/breeze-server/ip-location-api/'

// Send Email Api
export var SEND_EMAIL_API_BASE_URL = APP_BASE_URL + '/breeze-server/send-email-api/'

// Cookie KeyNames
export const LANG_COOKIE = 'Lang'