/*  global mixin
 *  This mixin is injected in all components
 *  These data and methods which include enums, constants and store elements are available everywhere
 */
import { mapState, mapWritableState, mapActions } from 'pinia'

import { useGlobalStore } from '@/stores/global'
import { useDbPageStore } from '@/stores/database/page'
import { useDbMenuStore } from '@/stores/database/menu'
import { useDbWidgetStore } from '@/stores/database/widget'
import { useSessionStore } from '@/stores/session'

import { STORAGE_API_BASE_URL } from '@/utils/constants'
import { MenuLocations, MenuItemTypes, MediaTypes, WidgetTypes, DefaultThumbnails, ThumbnailFormats, Currencies, Settings, WebsiteInfo } from '@/utils/enums'

import { useDevice } from "next-vue-device-detector"

import { formatDate, reformatDate } from '@/utils/chronos'
import { AccountAuthStatus } from '@/utils/enums'

export default{
    data() {
        return {
            isMobile: false,
            DefaultThumbnails,
            ThumbnailFormats,
            MenuLocations,
            MenuItemTypes,
            WidgetTypes,
            WebsiteInfo,
            Currencies,
            MediaTypes,
            Settings
        }
    },
    created() {
        const device = useDevice()

        this.isMobile = device.mobile

        if (this.userData &&
            this.userData.auth_status == AccountAuthStatus.NOT_COMPLETED &&
            this.$route.path !== '/account-verification') {
            this.$router.replace('/account-verification')
        }
    },
    computed: {
        ...mapState(useDbPageStore, ['pages']),
        ...mapState(useDbWidgetStore, ['widgets']),
        ...mapState(useDbMenuStore, ['menus', 'menuItems', 'menuLocations']),
        ...mapState(useGlobalStore, ['widgetsJSONData', 'sectionsJSONData']),
        ...mapWritableState(useGlobalStore, ['redirectAction', 'redirectTo', 'topProgressBar']),
        ...mapState(useSessionStore, ['userData'])
    },
    methods: {
        formatDate,
        reformatDate,
        ...mapActions(useGlobalStore, [
            'loadJSONData',

            'addItemInDB',
            'getItemFromDB',
            'updateItemInDB',
            'deleteItemInDB',
            'getAllItemsFromDB'
        ]),
        ...mapActions(useGlobalStore, {
            request: 'makeCustomRequestToDB'
        }),
        resolveLink(part) {
            if (part){

                if (this.$validator.isURL(part)) return encodeURI(part)
                else return encodeURI(STORAGE_API_BASE_URL + part)

            }

            return ''
        },
        tr(data) {

            return this.getTranslation(data)

        },
        getTranslation(data) {
            let parsedData

            try {
                parsedData = JSON.parse(data)
            } catch (e) {
                parsedData = {}
            }

            return parsedData[this.$i18n.locale.toUpperCase()] || ''
        },
        resolveHtmlLinks(data) {

            if (!data) return ''

            return this.getTranslation(data).replace(new RegExp('{{BASE_URL}}', 'g'), STORAGE_API_BASE_URL)

        }
    },
}