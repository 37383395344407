import { createPinia } from 'pinia'
import createPersistedState from "pinia-persistedstate";

import SecureLS from "secure-ls"

var ls = new SecureLS({ encodingType: 'aes', isCompression: false })

export default createPinia().use(createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
  })
)
