import { createApp } from 'vue'
import { createHead } from '@vueuse/head'

import App from './App.vue'

import getRouter from './router'
import pinia from './stores'
import i18n from './i18n'

import _ from 'lodash'
import validator from 'validator'
import ElementPlus from 'element-plus'
import Flicking from "@egjs/vue3-flicking"
import LoadScript from 'vue-plugin-load-script'
import CountryFlag from 'vue3-country-flag-icon'
import VueSmoothScroll from 'vue3-smooth-scroll'
import TextClamp from 'vue3-text-clamp'

import { Loading } from '@element-plus/icons'

import { VueCookieNext } from 'vue-cookie-next'
import { createDeviceDetector } from "next-vue-device-detector"

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import globalMixin from './mixins/global'

import { v4 as uuidv4 } from 'uuid'

import { useGlobalStore } from './stores/global'
import { useDbPageStore } from './stores/database/page'
import { useDbWidgetStore } from './stores/database/widget'
import { useDbSettingStore } from './stores/database/setting'
import { useDbWebsiteInfoStore } from './stores/database/website-info'

import 'normalize.css/normalize.css'
import "@egjs/vue3-flicking/dist/flicking.css"
import 'vue3-country-flag-icon/dist/CountryFlag.css'
import './assets/element-plus-theme/element-variables.scss'

async function launchApp() {

    /* Loading the data necessary to launch the application  */
    await useDbWebsiteInfoStore(pinia).initWebsiteInfoDB()
    await useDbSettingStore(pinia).getAllSettings()
    await useDbWidgetStore(pinia).getAllWidgets()
    await useGlobalStore(pinia).loadJSONData()
    await useDbPageStore(pinia).getAllPages()

    let router = await getRouter()

    /* Hide preloader */
    setTimeout(() => {

        document.querySelector('body').classList.add("loaded")

    }, 0)

    /* App Launch */
    library.add(fas)

    const app = createApp(App)
                    .use(i18n)
                    .use(pinia)
                    .use(router)
                    .use(TextClamp)
                    .use(LoadScript)
                    .use(ElementPlus)
                    .use(createHead())
                    .use(VueCookieNext)
                    .use(VueSmoothScroll)
                    .use(createDeviceDetector())
                    .component("Flicking", Flicking)
                    .component('font-awesome-layers-text', FontAwesomeLayersText)
                    .component('font-awesome-layers', FontAwesomeLayers)
                    .component('font-awesome-icon', FontAwesomeIcon)
                    .component('country-flag', CountryFlag)
                    .component('loading', Loading)

    app.config.globalProperties.$validator = validator
    app.config.globalProperties.$uuidv4 = uuidv4
    app.config.globalProperties.$_ = _

    app.mixin(globalMixin)

    router.isReady().then(() => {

        app.mount("#app");

    })

}

launchApp()
